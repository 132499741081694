import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { Advantages } from "../components/Advantages";
import { HandleSlider } from "../components/HandleSlider";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const pageData = {
  header: "Dzierżawa drukarek",
  mainTitle: "Druk na miarę",
  subtitle: "potrzeb Twojego biznesu!",
  paragraph: "",
};

const advElements = [
  {
    contentTitle: "Urządzenie",
    contentTxt:
      "Wynajmij zaawansowaną kserokopiarkę dostosowaną do wymagań Twojej firmy",
    imgSrc: require("../assets/images/offer-printing-lease/ikony_druk_1.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Instalacja",
    contentTxt:
      "Dostawa i profesjonalna instalacja  wynajmowanych urządzeń w siedzibie firmy - bez ukrytych opłat",
    imgSrc: require("../assets/images/offer-printing-lease/ikony_druk_5.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Eksploatacja",
    contentTxt:
      "Wszelkie materiały eksploatacyjne  jak tonery, bębny, rolki i inne części  są bezpłatnie wymieniane",
    imgSrc: require("../assets/images/offer-printing-lease/ikony_druk_2.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Serwis",
    contentTxt:
      "W ramach umowy, usługi  serwisowe są świadczone bez  dodatkowych opłat, włącznie  z bezpłatnym dojazdem do Klienta",
    imgSrc: require("../assets/images/offer-printing-lease/ikony_druk_3.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Wsparcie",
    contentTxt:
      "Oferujemy firmom zadalne wsparcie w codziennej  obsłudze urządzeń",
    imgSrc: require("../assets/images/offer-printing-lease/ikony_druk_4.svg")
      .default,
    alt: "Opis obrazka",
  },
];

const slidesContent = [
  {
    title: (
      <>
        <StaticImage
          src="../assets/images/offer-printing-lease/Epson_logo.svg"
          alt=""
        />
      </>
    ),
    subtitle: "L4266",
    leftCol: (
      <>
        <p>Rodzaj urządzenia:</p>
        <p>Technologia druku:</p>
        <br />
        <p>Rodzaj druku:</p>
        <p>Format druku:</p>
        <p>Szybkość druku cz:</p>
        <p>Szybkość druku kol:</p>
        <p>Skaner dwustronny:</p>
        <p>WiFi:</p>
        <p>Wbudowany faks:</p>
      </>
    ),

    rightCol: (
      <>
        <p>Wielofunkcyjne</p>
        <p>Atrament</p>
        <br />
        <p>Kolorowy</p>
        <p>A4</p>
        <p>33 [str/min] </p>
        <p>15 [str/min]</p>
        <p>Nie</p>
        <p>Tak</p>
        <p>Nie</p>
      </>
    ),

    imgBox: (
      <StaticImage
        className="desktopImg"
        src="../assets/images/offer-printing-lease/drukarki_dzierzawa_l4266.png"
        alt=""
      />
    ),
  },
  {
    title: (
      <>
        <StaticImage
          src="../assets/images/offer-printing-lease/Epson_logo.svg"
          alt=""
        />
      </>
    ),
    subtitle: "L6570",
    leftCol: (
      <>
        <p>Rodzaj urządzenia:</p>
        <p>Technologia druku:</p>
        <br />
        <p>Rodzaj druku:</p>
        <p>Format druku:</p>
        <p>Szybkość druku cz:</p>
        <p>Szybkość druku kol:</p>
        <p>Skaner dwustronny:</p>
        <p>WiFi:</p>
        <p>Wbudowany faks:</p>
      </>
    ),

    rightCol: (
      <>
        <p>Wielofunkcyjne</p>
        <p>Atrament</p>
        <br />
        <p>Kolorowy</p>
        <p>A4</p>
        <p>25 [str/min] </p>
        <p>25 [str/min]</p>
        <p>Tak</p>
        <p>Tak</p>
        <p>Tak</p>
      </>
    ),

    imgBox: (
      <StaticImage
        className="desktopImg"
        src="../assets/images/offer-printing-lease/drukarki_dzierzawa_l6570.png"
        alt=""
      />
    ),
  },
  {
    title: (
      <>
        <StaticImage
          src="../assets/images/offer-printing-lease/canon-logo.png"
          alt=""
        />
      </>
    ),
    subtitle: "GX4040",
    leftCol: (
      <>
        <p>Rodzaj urządzenia:</p>
        <p>Technologia druku:</p>
        <br />
        <p>Rodzaj druku:</p>
        <p>Format druku:</p>
        <p>Szybkość druku cz:</p>
        <p>Szybkość druku kol:</p>
        <p>Skaner dwustronny:</p>
        <p>WiFi:</p>
        <p>Wbudowany faks:</p>
      </>
    ),

    rightCol: (
      <>
        <p>Wielofunkcyjne</p>
        <p>Atrament</p>
        <br />
        <p>Kolorowy</p>
        <p>A4</p>
        <p>18 [str/min] </p>
        <p>13 [str/min]</p>
        <p>Tak</p>
        <p>Tak</p>
        <p>Tak</p>
      </>
    ),
    imgBox: (
      <StaticImage
        className="desktopImg"
        src="../assets/images/offer-printing-lease/drukarki_dzierzawa_gx4040.png"
        alt=""
      />
    ),
  },
  {
    title: (
      <>
        <StaticImage
          src="../assets/images/offer-printing-lease/canon-logo.png"
          alt=""
        />
      </>
    ),
    subtitle: "GX6040",
    leftCol: (
      <>
        <p>Rodzaj urządzenia:</p>
        <p>Technologia druku:</p>
        <br />
        <p>Rodzaj druku:</p>
        <p>Format druku:</p>
        <p>Szybkość druku cz:</p>
        <p>Szybkość druku kol:</p>
        <p>Skaner dwustronny:</p>
        <p>WiFi:</p>
        <p>Wbudowany faks:</p>
      </>
    ),

    rightCol: (
      <>
        <p>Wielofunkcyjne</p>
        <p>Atrament</p>
        <br />
        <p>Kolorowy</p>
        <p>A4</p>
        <p>24 [str/min] </p>
        <p>15 [str/min]</p>
        <p>Tak</p>
        <p>Tak</p>
        <p>Nie</p>
      </>
    ),

    imgBox: (
      <StaticImage
        className="desktopImg"
        src="../assets/images/offer-printing-lease/drukarki_dzierzawa_gx7040.png"
        alt=""
      />
    ),
  },
  {
    title: (
      <>
        <StaticImage
          src="../assets/images/offer-printing-lease/Ricoh.jpg"
          alt=""
        />
      </>
    ),
    subtitle: "MP 5055/6055",
    leftCol: (
      <>
        <p>Rodzaj urządzenia:</p>
        <p>Technologia druku:</p>
        <br />
        <p>Rodzaj druku:</p>
        <p>Format druku:</p>
        <p>Szybkość druku cz:</p>
        <p>Szybkość druku kol:</p>
        <p>Skaner dwustronny:</p>
        <p>WiFi:</p>
        <p>Wbudowany faks:</p>
      </>
    ),

    rightCol: (
      <>
        <p>Wielofunkcyjne</p>
        <p>Laserowe</p>
        <br />
        <p>Czarno-biały</p>
        <p>A3</p>
        <p>50 [str/min] </p>
        <p>n.d.</p>
        <p>Tak</p>
        <p>Tak</p>
        <p>Tak</p>
      </>
    ),

    imgBox: (
      <StaticImage
        className="desktopImg"
        src="../assets/images/offer-printing-lease/drukarki_dzierzaw_MP6055.png"
        alt=""
      />
    ),
  },
  {
    title: (
      <>
        <StaticImage
          src="../assets/images/offer-printing-lease/Ricoh.jpg"
          alt=""
        />
      </>
    ),
    subtitle: "MP 3504",
    leftCol: (
      <>
        <p>Rodzaj urządzenia:</p>
        <p>Technologia druku:</p>
        <br />
        <p>Rodzaj druku:</p>
        <p>Format druku:</p>
        <p>Szybkość druku cz:</p>
        <p>Szybkość druku kol:</p>
        <p>Skaner dwustronny:</p>
        <p>WiFi:</p>
        <p>Wbudowany faks:</p>
      </>
    ),

    rightCol: (
      <>
        <p>Wielofunkcyjne</p>
        <p>Laserowe</p>
        <br />
        <p>Kolorowy</p>
        <p>A3</p>
        <p>25 [str/min] </p>
        <p>35 [str/min]</p>
        <p>Tak</p>
        <p>Tak</p>
        <p>Tak</p>
      </>
    ),

    imgBox: (
      <StaticImage
        className="desktopImg"
        src="../assets/images/offer-printing-lease/drukarki_dzierzawa_MPC3504.png"
        alt=""
      />
    ),
  },
];

const PrintingLeasePage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`sign-page-info ${active} registers printing-lease`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="sign-page registers-page printing-lease-page">
          <div id="info" className="anchor"></div>
          <div className="aboutUs-container">
            <div className="imgContainer">
              <StaticImage src="../assets/images/offer-printing-lease/printing-lease1.jpg" />
              <div className="square"></div>
            </div>

            <div className="aboutUs txt">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Co warto wiedzieć <br />{" "}
                  <strong>o dzierżawie drukarek?</strong>
                </h2>
              </div>
              <p className="headerTxt">
                Outsourcing druku jest popularną strategią wśród firm, które
                chcą skoncentrować się na swojej głównej działalności,
                <b> oszczędzać czas i pieniądze</b> oraz korzystać z wysokiej
                jakości usług drukarskich. Jest to również elastyczne
                rozwiązanie, które pozwala firmom dostosować się do
                zmieniających się potrzeb biznesowych.
              </p>
              <p className="headerTxt">
                Firma, która decyduje się na wynajem drukarki, kserokopiarki lub
                urządzenia wielofunkcyjnego, nie musi martwić się o koszty
                zakupu sprzętu, ponieważ może wybrać urządzenia, które{" "}
                <b>najlepiej odpowiadają jej potrzebom.</b> Wynajem takich
                urządzeń to długoterminowe rozwiązanie, które nadal pozostaje
                opłacalne dla Twojej firmy. Dla małych i średnich
                przedsiębiorstw jest to idealny sposób na korzystanie z
                najnowszych dostępnych na rynku technologii.
              </p>
              <p className="headerTxt">
                Nasza oferta obejmuje urządzenia <b>Ricoh i Epson,</b> które
                spełnią oczekiwania nawet najbardziej wymagających użytkowników.
              </p>
              <Link to="#devices">
                <button className="btn-red right">
                  Zobacz ofertę urządzeń
                </button>
              </Link>
            </div>
          </div>
          <Advantages
            headerSubtitle={
              <>
                Dlaczego warto <br /> <strong>wydzierżawić urządzenie</strong>
              </>
            }
            // headerTxt={"Lorem ipsum"}
            advantageElements={advElements}
          />

          <div className="aboutUs-container onlyTxt">
            <div className="aboutUs txt">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Opieka serwisowa <br /> <strong>urządzeń drukujących</strong>
                </h2>
              </div>
              <p className="headerTxt">
                W przypadku awarii sprzętu oferujemy profesjonalne wsparcie
                serwisowe dostępne w ciągu <b>48 godzin.</b> Nasz zespół
                serwisantów działa sprawnie i skutecznie, gwarantując szybkie
                reagowanie na zgłoszenia.
              </p>
              <p className="headerTxt">
                Realizujemy <b>kompleksową pomoc serwisową</b> oraz niezbędne
                naprawy dla urządzeń marki Epson, Canon oraz Ricoh przywracając
                im pełną funkcjonalność w najkrótszym możliwym czasie. Nasi
                serwisanci posiadają wysokie kwalifikacje, które zostały
                potwierdzone odpowiednimi certyfikatami, co stanowi{" "}
                <b>gwarancję najwyższej jakości</b> świadczonych usług.
              </p>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  {" "}
                  <strong>W ramach umowy nasza opieka obejmuje:</strong>
                </p>

                <p className="headerTxt">
                  <b></b> bezpłatne zaopatrywanie w toner oraz części zamienne
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> profesjonalny i bezpłatny serwis
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b>stałe utrzymanie kopiarek w należytej sprawności
                  technicznej
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> Pełną kontrolę kosztów eksploatacji
                </p>
              </div>
            </div>
            <div className="imgContainer bottom">
              <StaticImage src="../assets/images/offer-printing-lease/drukarka.png" />
              <div className="square"></div>
            </div>
          </div>
          <div id="devices" className="anchor"></div>
          <div className={`sign-page-info ${active} qual-sign`}>
            <PageInfo
              mainTitle={pageData.mainTitle}
              // date={pageData.date}
              paragraph={pageData.paragraph}
              subtitle={pageData.subtitle}
            />
            <HandleSlider slidesContent={slidesContent} />
          </div>
        </section>
        <div className="footer-container-wrapper-form">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
            <div className="form-txt">
              <h2>Dział Obsługi Klienta</h2>
              <p>ds. Dzierżawy drukarek</p>
              <br />
              <p>
                Tel: <b>+48 83 344 70 02</b>
              </p>
              <p>
                Kom: <b>+48 726 337 000</b>
              </p>
              <br />
              <p>
                e-mail: <b>kontakt@jbmultimedia.pl</b>
              </p>
            </div>
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => <Seo title="Dzierzawa drukarek" />;
export default PrintingLeasePage;
